import Header from '@marco/frontend/layout/Header'
import * as React from 'react'
import Footer from '@marco/frontend/layout/Footer'
import { Helmet } from 'react-helmet'
import { FunctionComponentWithChildren } from '@marco/frontend/helpers/componentHelpers'
import Head from 'next/head'
import { Box, Flex } from '@chakra-ui/react'
import { HomeProps } from '@marco/frontend/pages'

const PageLayout: FunctionComponentWithChildren<
  HomeProps & { htmlTitle: string }
> = ({ htmlTitle, children, ...homeProps }) => (
  <Box h="100%" id="layout">
    <Head>
      <title>Marco Lallemant</title>
      <meta name="description" content={homeProps.info.siteDescription} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <Helmet title={htmlTitle}>
      <meta charSet="utf-8" />
    </Helmet>
    <Header {...homeProps} />
    <Flex
      direction="column"
      justify="space-between"
      minH="100vh"
      pt={[24, 24, 32]}
    >
      <Box w="100%" flex={1}>
        {children}
      </Box>
      <Footer {...homeProps} />
    </Flex>
  </Box>
)

export default PageLayout

import type {
  GetStaticPropsContext,
  InferGetStaticPropsType,
  NextPage,
} from 'next'
import HeroSection from '@marco/frontend/components/home/HeroSection'
import * as React from 'react'
import { getInfo } from '@marco/frontend/sanity/info'
import WorkSection from '@marco/frontend/components/home/WorkSection'
import PageLayout from '@marco/frontend/layout/PageLayout'
import { getProjects } from '@marco/frontend/sanity/projects'

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const info = await getInfo(locale)
  const projects = await getProjects(locale)
  return {
    props: { info, projects, locale: locale ?? 'en' },
  }
}

export type HomeProps = InferGetStaticPropsType<typeof getStaticProps>

const Home: NextPage<HomeProps> = (props) => (
  <PageLayout htmlTitle={'Marco Lallemant'} {...props}>
    <HeroSection {...props} />
    <WorkSection {...props} />
  </PageLayout>
)

export default Home

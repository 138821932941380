import * as React from 'react'
import { Flex, Image, Text, Container } from '@chakra-ui/react'
import { HomeProps } from '@marco/frontend/pages'
import nl2br from 'react-nl2br'
import { getImageUrl } from '@marco/frontend/sanity/sanityClient'

const HeroSection = ({ info }: HomeProps) => {
  return (
    <Container maxW="container.xl">
      <Flex
        direction={['column-reverse', 'column-reverse', 'row']}
        align="center"
        justify="space-evenly"
      >
        <Flex direction="column" maxW={600} p={8}>
          <Text textAlign="justify">
            <br />
            {nl2br(info.bio)}
          </Text>
        </Flex>
        <Image
          src={getImageUrl(info.portrait).url()}
          alt="Marco Lallemant"
          maxW={['100%', '50%']}
          maxH={600}
        />
      </Flex>
    </Container>
  )
}

export default HeroSection

import * as React from 'react'
import { RefObject, useEffect, useRef } from 'react'
import * as Sentry from '@sentry/nextjs'
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { HomeProps } from '@marco/frontend/pages'
import {
  getImageUrl,
  getLocalizedString,
} from '@marco/frontend/sanity/sanityClient'
import getYouTubeId from 'get-youtube-id'
import useResizeObserver from '@react-hook/resize-observer'
import { Artwork, YoutubeVideo } from '@marco/sanity/schemaTypes'

type WorkSectionProject = HomeProps['projects'][0]
type WorkSectionArtwork = WorkSectionProject['artworks'][0]

// Return the size of the element parent to the garget
const useContainerSize = (target: RefObject<HTMLElement>) => {
  const [size, setSize] = React.useState<DOMRect>()

  useEffect(() => {
    if (!target.current?.parentElement) {
      return
    }
    setSize(target.current.parentElement.getBoundingClientRect())
  }, [target])

  useResizeObserver(target.current?.parentElement ?? null, (entry) =>
    setSize(entry.contentRect),
  )

  return size
}

const ArtworkContainer = ({
  artwork,
  locale,
}: {
  artwork: WorkSectionArtwork
  locale: string
}) => {
  return (
    <Flex
      h="100%"
      direction="column"
      w="100%"
      align="center"
      justify="flex-end"
    >
      <Flex
        mt={8}
        flex={1}
        w="100%"
        direction="column"
        align="center"
        justify="flex-end"
      >
        <ArtworkContent artwork={artwork} locale={locale} />
      </Flex>
      <Heading mt={4} size="md">
        {getLocalizedString(artwork.title)}
      </Heading>
    </Flex>
  )
}

const ArtworkDisplay = ({
  artwork,
  locale,
}: {
  artwork: Artwork
  locale: string
}) => (
  <Image
    src={getImageUrl(artwork.image).url()}
    alt={getLocalizedString(artwork.title, locale)}
  />
)

const YoutubeVideoDisplay = ({
  artwork,
  locale,
}: {
  artwork: YoutubeVideo
  locale: string
}) => {
  const ref = useRef<HTMLElement>(null)
  const size = useContainerSize(ref)
  const id = getYouTubeId(artwork.url)

  const refSpan = <span ref={ref}></span>

  if (!id) {
    Sentry.captureException(
      new Error('Invalid youtube video for project artwork'),
      {
        extra: {
          youtubeVideoDocumentId: artwork._id,
          youtubeVideoTitle: artwork.title.en,
          youtubeVideoUrl: artwork.url,
        },
      },
    )
    return refSpan
  }

  if (!size) {
    return refSpan
  }

  const width = size.width
  const height = Math.max(size.height, 250)
  const src = `https://www.youtube-nocookie.com/embed/${id}`
  const title = getLocalizedString(artwork.title, locale)

  return (
    <>
      {refSpan}
      <iframe
        width={width}
        height={height}
        title={title}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  )
}

const ArtworkContent = ({
  artwork,
  locale,
}: {
  artwork: WorkSectionArtwork
  locale: string
}) => {
  if (artwork._type === 'youtubeVideo') {
    return <YoutubeVideoDisplay artwork={artwork} locale={locale} />
  }
  return <ArtworkDisplay artwork={artwork} locale={locale} />
}

const Project = ({
  project: { title, artworks, description },
  locale,
}: {
  project: WorkSectionProject
  locale: string
}) => {
  const gridPaddingExceptTop = [1, 4, 16]

  return (
    <Box mt={8}>
      <Heading ml={[2, 4, 16]} size="lg">
        {title}
      </Heading>
      {!!description && (
        <SimpleGrid
          mt={4}
          columns={[1, 1, 1, 2]}
          spacing={4}
          pr={gridPaddingExceptTop}
          pl={gridPaddingExceptTop}
        >
          <Text maxW={['none', 'none', 600]} textAlign="justify">
            {description}
          </Text>
        </SimpleGrid>
      )}

      <SimpleGrid
        columns={[1, 2]}
        mt={4}
        pr={gridPaddingExceptTop}
        pb={gridPaddingExceptTop}
        pl={gridPaddingExceptTop}
        spacing={4}
      >
        {artworks.map((artwork) => (
          <ArtworkContainer
            artwork={artwork}
            locale={locale}
            key={artwork._key}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

const WorkSection = ({ projects, locale }: HomeProps) => {
  return (
    <Container maxW="container.xl">
      {projects.map((project) => (
        <Project project={project} key={project._id} locale={locale} />
      ))}
    </Container>
  )
}

export default WorkSection

import React from 'react'
import { Flex, Heading, Container } from '@chakra-ui/react'
import Link from '@marco/frontend/components/ui/Link'
import { HomeProps } from '@marco/frontend/pages'
import { getLocalizedString } from '@marco/frontend/sanity/sanityClient'

const Header = ({ info: { headerLinks }, locale }: HomeProps) => (
  <Flex
    as="header"
    position="fixed"
    backgroundColor="white"
    left="0"
    height={[24, 24, 32]}
    width="100%"
    zIndex="1000"
  >
    <Container
      h="100%"
      flex={1}
      centerContent
      flexDirection={['column', 'column', 'row']}
      maxW="container.xl"
    >
      <Heading
        as={Link}
        href="/"
        size={['xl', '2xl']}
        ml={[0, 0, 8]}
        mt={[2, 4, 0]}
        _hover={{ textDecoration: 'none' }}
      >
        Marco Lallemant
      </Heading>
      <Flex
        flex={1}
        align="center"
        pr={[0, 0, 4]}
        justify={['center', 'flex-end']}
        flexWrap="wrap"
      >
        {headerLinks.map((link) => (
          <Link
            key={link._key}
            mx={[2, 4]}
            fontSize={['md', 'lg']}
            isExternal={true}
            href={link.url}
            color="green.500"
          >
            {getLocalizedString(link.title, locale)}
          </Link>
        ))}
      </Flex>
    </Container>
  </Flex>
)

export default Header
